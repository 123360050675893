import { createGlobalStyle } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'

import { DINPro } from './fonts'

export const GlobalThemeVariables = createGlobalStyle`
  :root {
    --reach-dialog: 1;

    // Typography
    --font-family: ${DINPro.style.fontFamily};
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 600;
    --line-height: 1.5;
    --line-height-heading: 1.15;

    // Colors
    --color-accent: ${colors.deepBlue};
    --color-secondary: ${colors.deepBlue};
    --color-success: #169222;
    --color-info: ${colors.deepBlue};
    --color-warning: ${colors.orange};
    --color-error: ${colors.errorRed};
    --color-fillPrimary: ${colors.white};
    --color-background: ${colors.backgroundWhite};

    // Common colored elements
    --border-color: ${colors.borderLightGrey};
    --color-text: ${colors.midGrey};
    --color-link: ${colors.deepBlue};
    --color-listMarker: ${colors.deepBlue};
    --color-tableRow: ${colors.lightGrey};
    --color-scrollbar-thumb: ${colors.deepBlue};
    --color-scrollbar-track: ${colors.lightGrey};

    --color-buttonText: ${colors.white};
    // Common element values
    --border-radius: 0; // doesn't need to be set but it's here for reference
    --box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    --focus-color: ${colors.fountainBlue};

    // Spinner colors
    --color-spinner-car: #dee9ef;
    --color-spinner-circle: #50a7ba;

    --flow-color-sidebarBackground: linear-gradient(90deg, #6793AE 0%, #50A7BA 97.99%);
    --color-buttonBackground: var(--flow-color-sidebarBackground);
  }

  .t-dark {
    --border-color: ${colors.darkmodeOffset};
    --color-background: ${colors.darkmode};
    --color-text: ${colors.white};
    --color-fillPrimary: ${colors.darkmodeOffset};
    --color-accent: ${colors.blueOnDark};
    --color-info: ${colors.deepBlue};
    --color-link: ${colors.blueOnDark};
    --color-listMarker: ${colors.blueOnDark};
    --color-tableRow: ${colors.dark};
    --color-scrollbar-thumb: ${colors.blueOnDark};
    --color-scrollbar-track: ${colors.darkmodeOffset};
    --focus-color: ${colors.blueOnDark};
  }
`

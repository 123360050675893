import styled, { css, type CSSProperties } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { mb, mt, pb, pl, pr, pt } from 'styled-components-spacing'

import { durations } from '../../constants/durations'
import { base, modularScale } from '../../constants/sizes'
import { between } from '../../utils/between'
import { mediaMax } from '../../utils/media'

const weights = {
  light: 'var(--font-weight-light, 300)',
  normal: 'var(--font-weight-normal, 400)',
  medium: 'var(--font-weight-medium, 500)',
  bold: 'var(--font-weight-bold, 700)',
}

const presets = {
  tiny: {
    fontSize: { xs: 10, md: 10 },
    fontWeight: weights.normal,
  },
  small: {
    fontSize: { xs: 12, md: 12 },
    fontWeight: weights.normal,
  },
  label: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: weights.bold,
  },
  labelLarge: {
    fontSize: { xs: 16, md: 20 },
    fontWeight: weights.bold,
  },
  labelTiny: {
    fontSize: { xs: 10, md: 14 },
    fontWeight: weights.medium,
  },
  labelSmall: {
    fontSize: { xs: 14, md: 14 },
    fontWeight: weights.medium,
  },
  textSmall: {
    fontSize: { xs: 14, md: 14 },
    fontWeight: weights.normal,
  },
  textTiny: {
    fontSize: { xs: 12, md: 13 },
    fontWeight: weights.normal,
  },
  text: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: weights.normal,
  },
  textLarge: {
    fontSize: { xs: 16, md: 18 },
    fontWeight: weights.normal,
  },
  textExtraLarge: {
    fontSize: { xs: 16, md: 24 },
    fontWeight: weights.normal,
  },
  subtitle: {
    fontSize: { xs: 16, md: 24 },
    fontWeight: weights.light,
    lineHeight: '--line-height',
  },
  headline: {
    fontSize: { xs: 24, md: 30 },
    fontWeight: weights.bold,
    lineHeight: '--line-height-heading',
  },
  headlineSmall: {
    fontSize: { xs: 16, md: 24 },
    fontWeight: weights.bold,
    lineHeight: '--line-height',
  },
  headlineMedium: {
    fontSize: { xs: 20, md: 30 },
    fontWeight: weights.bold,
    lineHeight: '--line-height-heading',
  },
  headlineMediumLarge: {
    fontSize: { xs: 24, md: 36 },
    fontWeight: weights.bold,
    lineHeight: '--line-height-heading',
  },
  headlineLarge: {
    fontSize: { xs: 24, md: 48 },
    fontWeight: weights.bold,
    lineHeight: '--line-height-heading',
  },
  headlineExtraLarge: {
    fontSize: { xs: 32, md: 64 },
    fontWeight: weights.bold,
    lineHeight: '--line-height-heading',
  },
}

type Breakpoint = 'xs' | 'md' | 'lg' | 'mlg' | 'xl'
type BreakpointMap<T> = Partial<Record<Breakpoint, T>>
export type Presets = keyof typeof presets & {
  lineHeight?: '--line-height-heading' | '--line-height'
}
export type Weight = keyof typeof weights

/**
 * Helper for props that are piped directly to their respective CSS property.
 */
export type CSSValue<K extends keyof CSSProperties> =
  | CSSProperties[K]
  | BreakpointMap<CSSProperties[K]>

export interface TypeProps {
  preset?: Presets
  size?: BreakpointMap<number>
  weight?: Weight | number
  top?: CSSValue<'top'>
  left?: CSSValue<'left'>
  right?: CSSValue<'right'>
  bottom?: CSSValue<'bottom'>
  mTop?: CSSValue<'top'>
  mBottom?: CSSValue<'bottom'>
  inline?: boolean
  block?: boolean
  inlineBlock?: boolean
  spacing?: number
  lineHeight?: CSSValue<'lineHeight'>
  maxWidth?: CSSValue<'maxWidth'>
  multiline?: boolean
  duration?: number
  italic?: boolean
  case?: string
  textTransform?: CSSValue<'textTransform'>
  textAlign?: 'left' | 'center' | 'right'
  textDecoration?: string
  opacity?: number
  underline?: boolean // does not seem to be used, but is provided by SubscriptionDrawer
  bold?: boolean // does not seem to be used, but is provided by Cart
}

export const typeFontSize = (size: TypeProps['size']) => {
  const fontSizeCss = map(
    size,
    val => `font-size: ${between(val / modularScale, val)};`
  )

  return css`
    ${fontSizeCss}
  `
}

/**
 * Typography component
 *
 * The `Type` component is a styled paragraph (`<p>`) element that allows for customizable typography.
 * It supports various font sizes, weights, and presets to ensure consistent and responsive text styling.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.preset] - An optional preset name that applies predefined font size and weight settings.
 * @param {Object} [props.size={ xs: base }] - An optional object defining font sizes for different breakpoints.
 * @param {number} [props.size.xs] - Font size for extra small screens.
 * @param {number} [props.size.sm] - Font size for small screens.
 * @param {number} [props.size.md] - Font size for medium screens.
 * @param {number} [props.size.lg] - Font size for large screens.
 * @param {number} [props.size.xl] - Font size for extra large screens.
 * @param {string} [props.weight] - An optional font weight. If not provided, it defaults to the weight defined in the preset or inherits from the parent element.
 * @param {boolean} [props.underline] - If true, the text is underlined.
 * @param {boolean} [props.bold] - If true, the text is bold.
 * @returns {JSX.Element} The rendered `Type` component.
 */
export const Type = styled.p<TypeProps>`
  ${({ preset, size = { xs: base } }) =>
    typeFontSize(preset ? presets[preset].fontSize : size)};

  font-weight: ${({ preset, weight }) =>
    weight
      ? weight && weights[weight]
      : preset
        ? presets[preset].fontWeight
        : 'inherit'};

  ${({ size = {} }) =>
    size.xs &&
    mediaMax.md(css`
      font-size: ${size.xs}px;
    `)};

  ${({ preset }) =>
    presets[preset] &&
    mediaMax.md(css`
      font-size: ${presets[preset].fontSize.xs}px;
    `)};

  ${({ top, theme }) => pt(top, theme)};
  ${({ top }) =>
    top &&
    (top as BreakpointMap<number>).xs &&
    mediaMax.md(css`
      padding-top: ${(top as BreakpointMap<number>).xs * base}px;
    `)};

  ${({ bottom, theme }) => pb(bottom, theme)};
  ${({ bottom }) =>
    bottom &&
    (bottom as BreakpointMap<number>).xs &&
    mediaMax.md(css`
      padding-bottom: ${(bottom as BreakpointMap<number>).xs * base}px;
    `)};

  ${({ left, theme }) => pl(left, theme)};
  ${({ left }) =>
    left &&
    (left as BreakpointMap<number>).xs &&
    mediaMax.md(css`
      padding-left: ${(left as BreakpointMap<number>).xs * base}px;
    `)};

  ${({ right, theme }) => pr(right, theme)};
  ${({ right }) =>
    right &&
    (right as BreakpointMap<number>).xs &&
    mediaMax.md(css`
      padding-right: ${(right as BreakpointMap<number>).xs * base}px;
    `)};

  ${({ mTop, theme }) => mt(mTop, theme)};
  ${({ mTop }) =>
    mTop &&
    (mTop as BreakpointMap<number>).xs &&
    mediaMax.md(css`
      margin-top: ${(mTop as BreakpointMap<number>).xs * base}px;
    `)};

  ${({ mBottom, theme }) => mb(mBottom, theme)};
  ${({ mBottom }) =>
    mBottom &&
    (mBottom as BreakpointMap<number>).xs &&
    mediaMax.md(css`
      margin-bottom: ${(mBottom as BreakpointMap<number>).xs * base}px;
    `)};

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `};

  ${({ inlineBlock }) => inlineBlock && css({ display: 'inline-block' })}

  ${({ block }) =>
    block &&
    css`
      display: block;
    `};

  ${({ spacing }) =>
    spacing &&
    css`
      letter-spacing: ${spacing};
    `};

  ${({ lineHeight, preset }) =>
    lineHeight
      ? map(
          lineHeight,
          val => css`
            line-height: ${val};
          `
        )
      : preset && presets[preset]?.lineHeight
        ? css`
            line-height: var(${presets[preset].lineHeight}, inherit);
          `
        : 'inherit'};

  ${({ maxWidth = 'initial' }) =>
    map(maxWidth, val => `max-width: ${between(val / modularScale, val)};`)};

  ${({ multiline }) =>
    multiline &&
    css`
      white-space: pre-line;
    `};

  ${({ duration = durations.medium }) => css`
    transition: color ${duration}ms;
  `}

  color: ${props => (props.color ? props.color : 'inherit')};
  font-style: ${props => (props.italic ? 'italic' : 'initial')};
  text-transform: ${props => props.textTransform || props.case || 'inherit'};
  text-align: ${props => props.textAlign || 'inherit'};
  text-decoration: ${props => props.textDecoration || 'inherit'};

  opacity: ${props => props.opacity || 1};

  i,
  em {
    font-style: italic;
  }

  b {
    font-weight: ${weights.medium};
  }

  strong {
    font-weight: ${weights.bold};
  }

  u {
    text-decoration: underline;
  }

  code {
    font-family: monospace;
  }
`
